import React from 'react'
import { Helmet } from 'react-helmet'

export default function Meta() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>The Veridian Isles</title>
      <link rel="canonical" href="https://veridian.gg" />
      <meta name="description" content="Explore the Veridian Isles, a hidden gem in a mist-covered archipelago, where the Old Pioneers' legacy awaits amid pristine landscapes and untold resources." />
    </Helmet>
  )
}