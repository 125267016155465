import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icons } from '../../ui/icons.jsx';
import { UserState } from '../../../lib/state.js';
import { Button } from '../../ui/button.jsx';


export default function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const user = UserState.use();

  const toggleMenu = () => { setIsOpen(!isOpen); }


  return (
    <>
      <div>
        <Button className="p-2 text-gray-700 hover:text-white transition-colors md:hidden inline-block" variant="outlined" onClick={toggleMenu}>
          <i className="fa-solid fa-bars text-2xl"></i>
        </Button>
      </div>

      <div className={`absolute top-0 left-0 z-10 w-full h-full bg-white transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}>
        <div className="p-4">
          <div className="flex items-center justify-between">
            <span className="text-lg font-medium">Menu</span>
            <button className="p-2 text-black" onClick={toggleMenu}>
              <i className="fas fa-window-close text-2xl"></i>
            </button>
          </div>
          <ul className="mt-4">
            <li onClick={toggleMenu}><Link to="/" className="block p-2 text-gray-800">Home</Link></li>
            <li onClick={toggleMenu}><Link to="/dashboard" className="block p-2 text-gray-800">Dashboard</Link></li>
            <li>
              <a href={`${process.env.REACT_APP_ENDPOINT}/logout`} className="block p-2 text-blue-500">Log Out</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

