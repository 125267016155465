import { UserState } from "./state.js"


export function generateAvatar() {
  const user = UserState.get();
  console.log(user)

  if (user.loggedIn) {
    //return discord avatar
    return `https://cdn.discordapp.com/avatars/${user.discordid}/${user.avatar}.png`;
  } else {
    return "https://s3.us-east-2.amazonaws.com/harbor-production-s3bucket64cb25af-jduyhqalpufc/project/3/eea404eb48c1f60695f79c5fa0bb7d9a91761cdd7d85c6d07cf0f0a27b800af5.png"
  }
}

//generate first letter of username
export function generateInitials() {
  const user = UserState.get();
  if (user.loggedIn) {
    return user.username.charAt(0).toUpperCase();
  } else {
    return "?";
  }
}