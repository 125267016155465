import { Routes, Route, Link } from "react-router-dom";
import socket from "./lib/socket";
import { UserState, GlobalLoading } from "./lib/state";
import Loading from "./components/loading"
import VeridianUIAlert from "./components/verdian-ui/alert/index.js"
import Meta from "./components/meta/index.js"
import NotLoggedIn from "./components/partials/notLoggedIn";
import Layout from "./Layout.js";
import Home from "./pages/home/index.js";


export default function App() {

  const loading = GlobalLoading.use();
  const user = UserState.use();

  if (!loading) {
    return <Loading />
  }

  if (!user.loggedIn) {
    return <NotLoggedIn />
  }

  return (
    <>
      <Meta />
      <div className="dark">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </div>
    </>
  );
}




function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
      <VeridianUIAlert />

    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link className="text-blue-700" to="/">Go to the home page</Link>
      </p>
    </div>
  );
}