import React from "react"
import Meta from "../meta/index.js"

export default function Loading() {
  return (
    <>
      <Meta />
      <div className="w-full h-screen grid items-center justify-center decoration-sky-300">
        <div>
          <i className="fa-solid fa-sync fa-spin"></i> &nbsp;Loading...
        </div>
      </div>
    </>
  )
}