import React from "react";
import { Separator } from "../../components/ui/separator";


export default function Footer() {

  const year = new Date().getFullYear();

  return (
    <div className="mt-8">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 my-4 text-center md:text-left">
        <div>
          <div className="text-2xl mb-4 font-bold">Marketplace</div>
          <div className="text-gray-400 text-md font-medium">
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="text-2xl mb-4 font-bold">Marketplace</div>
          <div className="text-gray-400 text-md font-medium">
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="text-2xl mb-4 font-bold">Features</div>
          <div className="text-gray-400 text-md font-medium">
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="text-2xl mb-4 font-bold">Support</div>
          <div className="text-gray-400 text-md font-medium">
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
            <div className="mb-1.5">
              <a className="#">
                Link Here
              </a>
            </div>
          </div>
        </div>
      </div>
      <Separator className="my-8" />
      <div className="my-4 text-sm">
        <div className="grid items-center justify-center gap-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="">Made with ☕️ by <a className="underline" href="https://gibranali.com" target="_blank">Dr. Gibran Ali</a></div>
            <div className="grid gap-4 md:gap-0 grid-cols-2 text-center">
              <div>Link one</div>
              <div>Link two</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}