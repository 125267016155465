import react from 'react';
import Meta from '../meta/index.js';
import { Button } from '../ui/button.jsx';
import VeridianUIDialog from '../verdian-ui/dialog/index.js';
import { Separator } from '../ui/separator.jsx';

export default function NotLoggedIn() {
  return <>
    <Meta />
    <div className="w-full h-screen grid items-center justify-center dark">
      <div className="text-center">
        <div className="text-center mb-6 hover:scale-105 transition-all">
          <img src="https://s3.us-east-2.amazonaws.com/harbor-production-s3bucket64cb25af-jduyhqalpufc/project/3/6a6bd2839692d4c42128240bd02ed6672a90560a5c570a4c3a06f20a52d16bfe.webp" alt="Veridian Logo" className="w-48 h-48 mx-auto" />
        </div>
        <div>
          <a className="text-blue-700" href={`${process.env.REACT_APP_ENDPOINT}/login`}>
            <Button className="bg-slate-300 hover:bg-white w-full">
              <i className="fa-brands fa-discord"></i> &nbsp; Please Login
            </Button>
          </a>
        </div>
        <div className='mt-3'>
          <VeridianUIDialog className="bg-green-600 shadow-lg text-white hover:bg-green-500 py-1.5 px-2 w-full rounded-sm text-sm" title="Coming Soon" content="We will release the bot publicly soon. Get in touch with us for beta access to your server. Ping 'gibigbig' in discord.gg/otaku server. My DMS are closed.">
            <i className="fa-solid fa-circle-plus"></i>  &nbsp; Add Bot
          </VeridianUIDialog>
        </div>
        <div className='grid gap-8 justify-center items-center text-sm text-gray-600 mt-6 font-sem'>
          <div className='flex h-5 items-center space-x-4 text-sm'>
            <a className='hover:text-white transition-colors' href="https://gibigbig.gitbook.io/veridian-isles/" target="_blank">Help</a>
            <Separator orientation="vertical" />
            <VeridianUIDialog title="Under Development" content="The Discord server is under construction. Come back later for further updates">
              <span className='hover:text-white transition-colors'>Community</span>
            </VeridianUIDialog>
          </div>
        </div>
      </div>
    </div >
  </>
}