import React from 'react';
import VeridianUIDrawer from '../../components/verdian-ui/drawer/index.js';
import Carousel from '../../components/verdian-ui/carousel/index.js';
import { Button } from '../../components/ui/button.jsx';


export default function Home() {

  const cards = [
    {
      card: (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <img src="https://img.shoob.gg/uploaded-images/0_31718543802243.png" alt="test" className="w-full lg:w-96 rounded-lg shadow-md hover:scale-105 transition-all" />
          </div>
          <div className='text-center md:text-left items-center grid'>
            <div>
              <div className='text-sm'>
                Selling fast 🔥
              </div>
              <div className='text-2xl font-bold my-2'>
                Leshen Island
              </div>
              <div className='max-w-96'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </div>
              <div className='my-8'>
                <Button>This is a test</Button>
              </div>
            </div>
          </div>
        </div>),
      classes: "rounded-lg min-h-96 lg:h-[600px]",
      style: { background: "url(https://s3.us-east-2.amazonaws.com/harbor-production-s3bucket64cb25af-jduyhqalpufc/project/3/f01d2ecd87f70be9741fd21e4e43da30d8c4ffbd6de611121741fd32e00a1a66.png) repeat center center / cover" },
    },
    {
      card: (
        <div className="">
          <img src="https://img.shoob.gg/uploaded-images/0_31718543802243.png" alt="test" className="h-48 rounded-lg shadow-md" />
        </div>),
      classes: "rounded-lg h-96 lg:h-[600px] bg-green-500/5",
      style: {},
    },
    {
      card: (
        <div className="">
          <img src="https://img.shoob.gg/uploaded-images/0_31718543802243.png" alt="test" className="h-48 rounded-lg shadow-md" />
        </div>),
      classes: "rounded-lg h-96 lg:h-[600px] bg-red-500/5",
      style: {},
    },
  ];

  return (
    <div className='mt-8'>
      {/*<VeridianUIDrawer openText="Test the drawer" /> */}
      <Carousel cards={cards} />

      <div className='p-8 rounded-lg border border-gray-700/30 my-8'>
        <div className='text-2xl font-bold text-gray-200 border-b border-gray-700/30 pb-3 mb-8'>Latest Items</div>
        <div>
          items here
        </div>

      </div>

      <div className='p-8 rounded-lg border border-gray-700/30 mb-8 bg-[#63dafe33]'>
        <div className='text-2xl font-bold text-gray-200 border-b border-gray-700/30 pb-3 mb-8'>Latest Items</div>
        <div>
          items here
        </div>
      </div>

      <div className='p-8 rounded-lg border border-gray-700/30 mb-8 bg-[#282c4b]'>
        <div className='text-2xl font-bold text-gray-200 border-b border-gray-700/30 pb-3 mb-8'>Latest Items</div>
        <div>
          items here
        </div>
      </div>

    </div>
  );
}