import * as React from "react"
import { Link } from "react-router-dom"

import { cn } from "../../../lib/utils.js"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../../ui/navigation-menu.jsx"
import Search from "../../partials/search.js"
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar.jsx"
import { generateAvatar, generateInitials } from "../../../lib/functions.js"
import { ProfileDropDown } from "../../partials/profileDropDown.js"

const components = [
  {
    title: "Alert Dialog",
    href: "/docs/primitives/alert-dialog",
    description:
      "A modal dialog that interrupts the user with important content and expects a response.",
  },
  {
    title: "Hover Card",
    href: "/docs/primitives/hover-card",
    description:
      "For sighted users to preview content available behind a link.",
  },
  {
    title: "Progress",
    href: "/docs/primitives/progress",
    description:
      "Displays an indicator showing the completion progress of a task, typically displayed as a progress bar.",
  },
  {
    title: "Scroll-area",
    href: "/docs/primitives/scroll-area",
    description: "Visually or semantically separates content.",
  },
  {
    title: "Tabs",
    href: "/docs/primitives/tabs",
    description:
      "A set of layered sections of content—known as tab panels—that are displayed one at a time.",
  },
  {
    title: "Tooltip",
    href: "/docs/primitives/tooltip",
    description:
      "A popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it.",
  },
]

export default function VeridianUIMenu() {

  return (
    <NavigationMenu>
      <NavigationMenuList className="">
        <NavigationMenuItem className="flex-none pr-4 hidden lg:inline-block">
          <Link to="/">
            <div style={{ backgroundImage: "url(https://s3.us-east-2.amazonaws.com/harbor-production-s3bucket64cb25af-jduyhqalpufc/project/3/feabd325552540d7c76caa45998aef25e65161b3d061e14f31a59824c00ee40a.png)" }} className={`bg-cover h-12 w-12`}>
              &nbsp;
            </div>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem className="flex-none">
          <NavigationMenuTrigger className={`bg-transparent hover:bg-transparent`}>Getting started</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink asChild>
                  <a
                    className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                    href="/"
                  >
                    <div className="mb-2 mt-4 text-lg font-medium">
                      shadcn/ui
                    </div>
                    <p className="text-sm leading-tight text-muted-foreground">
                      Beautifully designed components built with Radix UI and
                      Tailwind CSS.
                    </p>
                  </a>
                </NavigationMenuLink>
              </li>
              <ListItem href="/docs" title="Introduction">
                Re-usable components built using Radix UI and Tailwind CSS.
              </ListItem>
              <ListItem href="/docs/installation" title="Installation">
                How to install dependencies and structure your app.
              </ListItem>
              <ListItem href="/docs/primitives/typography" title="Typography">
                Styles for headings, paragraphs, lists...etc
              </ListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem className="flex-none">
          <NavigationMenuTrigger className={`bg-transparent hover:bg-transparent`}>Components</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {components.map((component) => (
                <ListItem
                  key={component.title}
                  title={component.title}
                  href={component.href}
                >
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem className="flex-none" >
          <Link to="/dashboard" passHref>
            <NavigationMenuLink className={`${navigationMenuTriggerStyle()} bg-transparent hover:bg-transparent`}>
              Dashboard
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem className={`${navigationMenuTriggerStyle()} bg-transparent hover:bg-transparent pr-4 grow`}>
          &nbsp;
        </NavigationMenuItem>
        <NavigationMenuItem className="flex-none">
          <Search />
        </NavigationMenuItem>
        <NavigationMenuItem className="flex-none flex">
          <ProfileDropDown>
            <Avatar className="mr-5 cursor-pointer border-4">
              <AvatarImage src={generateAvatar()} />
              <AvatarFallback>{generateInitials()}</AvatarFallback>
            </Avatar>
          </ProfileDropDown>

        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const ListItem = React.forwardRef(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"
