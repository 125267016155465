import React from "react";
import { Outlet } from "react-router-dom";
import VeridianUIMenu from "./components/verdian-ui/navbar/index.js";
import MobileMenu from "./components/verdian-ui/navbar/mobile.js";
import Footer from "./pages/footer/index.js";


export default function Layout() {
  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="pb-6 hidden md:grid">
        <VeridianUIMenu />
      </div>
      <div className="items-center pb-6 grid md:hidden">
        <MobileMenu />
      </div>
      <div className="">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}