import io from "socket.io-client";
import { UserState, GlobalLoading } from "./state";

console.log(process.env.REACT_APP_ENDPOINT);

const socket = io(process.env.REACT_APP_ENDPOINT, {
  transports: ["websocket"],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: Infinity,
  autoConnect: true,
  forceNew: true
});


socket.on("connect", () => {
  socket.emit("init");
});

socket.on("initres", (data) => {
  UserState.set(data);
  GlobalLoading.set(true)
});

socket.on("disconnect", () => {
  console.log("disconnected");
});