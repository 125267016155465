import React, { useEffect, useRef, useState } from "react";
import { Input } from "../ui/input.jsx"; // Update the import path as necessary

const Search = () => {
  const searchRef = useRef(null);
  const [show, setShow] = useState(true);
  const [value, setValue] = useState("");
  const [opacity, setOpacity] = useState("opacity-35");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "/" && document.activeElement !== searchRef.current) {
        event.preventDefault();
        searchRef.current?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Focus and blur event listeners
    const handleFocus = () => setOpacity("opacity-100");
    const handleBlur = () => setOpacity("opacity-35");

    const currentRef = searchRef.current;
    currentRef?.addEventListener("focus", handleFocus);
    currentRef?.addEventListener("blur", handleBlur);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      currentRef?.removeEventListener("focus", handleFocus);
      currentRef?.removeEventListener("blur", handleBlur);
    };
  }, []);

  return (
    <div className="relative mr-4 w-full md:w-32 lg:w-96">
      <Input
        ref={searchRef}
        onChange={(e) => {
          const newValue = e.target.value;
          setValue(newValue);
          setShow(newValue.length === 0);
        }}
        value={value}
        type="search"
        className="w-full pl-4 bg-white/5"
        placeholder="Search..."
        aria-label="Search"
      />
      {show && (
        <div className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${opacity}`}>
          <img src="https://s3.us-east-2.amazonaws.com/harbor-production-s3bucket64cb25af-jduyhqalpufc/project/3/dbbe51ff379cd1e49e0780eb380784815d2ffbecf55c73e5d89908fc19eca228.png" alt="Search Icon" className="h-8 w-8 rounded-sm" />
        </div>
      )}
    </div>
  );
};

export default Search;
