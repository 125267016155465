import * as React from "react"
import Autoplay from "embla-carousel-autoplay"

import { Card, CardContent } from "../../ui/card.jsx"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../ui/carousel.jsx"


export default function CarouselPlugin({ cards }) {
  const plugin = React.useRef(
    Autoplay({ delay: 2000, stopOnInteraction: true })
  )

  return (
    <Carousel
      plugins={[plugin.current]}
      className="w-full"
      onMouseEnter={plugin.current.stop}
      onMouseLeave={plugin.current.reset}
    >
      <CarouselContent>
        {
          cards.map((card, index) => (
            <CarouselItem key={index}>

              <div className="p-1" key={`carousel-${index}`}>
                <Card>
                  <CardContent style={card.style} className={`flex items-center justify-center p-6 ${card.classes}`}>
                    {card.card}
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>

          ))

        }
      </CarouselContent>
      <CarouselPrevious className="hidden lg:inline-flex" />
      <CarouselNext className="hidden lg:inline-flex" />
    </Carousel>



  )
}
